import React, { useState, useEffect } from 'react';

const ImageLoad = React.memo(({ src, alt = "", className }) => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// start loading original image
		const imageToLoad = new Image();
		imageToLoad.src = src;
		imageToLoad.onload = () => {
			// When image is loaded replace the src and set loading to false
			setLoading(false);
		}
	}, [src])

	if (loading) {
		return (
			<div style={{ display: 'inline-block' }} className={className}>
				Loading
			</div>
		);
	}
	else {
		return (
			<img
				src={src}
				alt={alt}
				className={className}
			/>
		)
	}
});

export default ImageLoad;
import React, { useState } from 'react';

import IframeGenerator from './IframeGenerator';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import PodcastService from './PodcastService';
import ImageLoad from './ImageLoad';

import ColorInput from './ColorInput';

import LoadingIndicator from './resources/loading.svg';

let searchId = false;

function WidgetGeneratorPane() {
	const defaultPodcastId = 920666;

	const [episodes, setEpisodes] = useState(false);

	const [activePodcastId,setActivePodcastId] = useState(defaultPodcastId);
	const [activePodcastName,setActivePodcastName] = useState('');

	const [activeEpisodeId,setActiveEpisodeId] = useState(0);
	const [activeEpisodeName,setActiveEpisodeName] = useState('');	

	const [searchedPodcasts,setSearchedPodcasts] = useState('');
	const [showPodcastInfo,setShowPodcastInfo] = useState(false);
	const [episodeCount,setEpisodeCount] = useState(5);
	const [widgetWidth,setWidgetWidth] = useState(400);
	const [widgetHeight,setWidgetHeight] = useState(500);
	const [widgetType,setWidgetType] = useState('list');
	const [episodeType,setEpisodeType] = useState('latest');
	const [podcastSearchText,setPodcastSearchText] = useState('');

	const [primaryColor,setPrimaryColor] = useState('#0176e5');
	const [secondaryColor,setSecondaryColor] = useState('#FFFFFF');
	const [backgroundColor,setBackgroundColor] = useState('#FFFFFF');
	const [textColor,setTextColor] = useState('#000000');

	const searchForPodcast = (query) => {
		if (query === '') {
			setSearchedPodcasts('');
			return;
		}
		try {
			PodcastService.abortSearch();
			clearTimeout(searchId);
		}
		catch(error) {
			console.log(error);
		}
		setSearchedPodcasts('loading');
		searchId = setTimeout(() => {
			PodcastService.search(query)
			.then((result) => {
				setSearchedPodcasts(result);
			})
			.catch((error) => {
				console.log(error);
			});
		},500);
	};
	const getLatestEpisodes = () => {
		PodcastService.retrieveEpisodes(activePodcastId,50)
		.then((episodes) => {
			setEpisodes(episodes);
		});
	};
	
	const changeShowPodcastInfo = (event) => {
		setShowPodcastInfo(event.target.checked);
	};
	const changeWidth = (event) => {
		if (event.target) {
			setWidgetWidth(event.target.value);
		}
		else {
			setWidgetWidth(event);
		}
	};
	const changeHeight = (event) => {
		if (event.target) {
			setWidgetHeight(event.target.value);
		}
		else {
			setWidgetHeight(event);
		}
	};
	const changeEpisodeCount = (event) => {
		setEpisodeCount(event.target.value);
	};
	const changeWidgetType = (event) => {
		setWidgetType(event.target.value);
	};
	const changeEpisodeType = (event) => {
		setEpisodeType(event.target.value);
		getLatestEpisodes();
	};
	const changePodcastSearchText = (event) => {
		setPodcastSearchText(event.target.value);
		searchForPodcast(event.target.value);
	};

	return (
		<div className="widgetGeneratorPage">
			<div className="menu">
				<a href="https://www.podfriend.com">Go to www.podfriend.com</a>
			</div>
			<h1>Podfriend widgets</h1>
			<div className="explanation">
				<img src="https://www.podfriend.com/images/logo/podfriend_logo.svg" className="logo" alt="Podfriend mascot" />

				<div className="speech-bubble">
					<div className="speech-bubble-triangle"></div>
					<div className="speech-bubble-content">

						<h1>Create a nice podcast widget for your website here!</h1>

						<p>Want to give your listeners a preview of what they can expect from your podcast?</p>
						<p>You can use this page to create a widget to put onto your page, that will allow listeners to take a sneak peak of your podcast!</p>
						<p>Best of all? It's <b>free!</b></p>
					</div>
				</div>
			</div>

			<div className="configAndPreview">
				<div className="configuration">
					<h2 className="subHeadline">
						First let's find the podcast <span>you want to display</span>
					</h2>

					<div className="configurationPanel">
						<div className="configurationHeadline">First step, find your podcast by searching here</div>
						
							<input type="text" name="podcastSearch" value={podcastSearchText} onChange={changePodcastSearchText} />
							
							{ searchedPodcasts !== '' &&
								<div className="podcastList">
									{ searchedPodcasts === 'loading' &&
										<div className="loadingPodcasts">
											<div>
												loading podcasts
											</div>
											<div>
												<img src={LoadingIndicator} className="loadingIndicator" alt="" />
											</div>
										</div>
									}
									{ searchedPodcasts.count === 0 &&
										<div>
											No results found
										</div>
									}
									{ searchedPodcasts.count > 0 &&
										searchedPodcasts.feeds.map((podcast,index) => {
											return (
												<div className={'searchPodcastItem' + (activePodcastId === podcast.id ? ' active' : '')} key={podcast.id} onClick={() => { setActivePodcastId(podcast.id); setActivePodcastName(podcast.title); }}>
													<ImageLoad src={podcast.image ? podcast.image : podcast.artwork} alt={podcast.title + ' cover'} className='cover' />
													<div className="searchPodcastItemTitle">
														{podcast.title}
													</div>
												</div>
											);
										})
									}
								</div>
							}
							{ activePodcastName &&
								<div className="selectedPodcastName">
									Selected: {activePodcastName}
								</div>
							}
					</div>
					<h2 className="subHeadline">
						Now we decide on the information <span>to show</span>
					</h2>

					<div className="configurationPanel">

						<div className="configurationHeadline">Do you want to show information about your podcast in the widget?</div>
						<div className="configurationItem">
							<div>
								<input type="checkbox" checked={showPodcastInfo} onChange={changeShowPodcastInfo} /> Show information about my podcast
							</div>
						</div>

						<div className="configurationHeadline">What kind of widget do you want?</div>

						<div className="configurationItem">
							<div>
								<input type="radio" name="widgetType" value="episode" onChange={changeWidgetType} /> Show just one episode
							</div>
							<div>
								<input type="radio" name="widgetType" value="list" defaultChecked onChange={changeWidgetType} /> Show a list of episodes
							</div>
						</div>

						{ widgetType === 'episode' && 
							<>
							<div className="configurationHeadline">What episode do you want to show?</div>
							<div className="configurationItem">
								<div>
									<input type="radio" name="episodeType" value="latest" defaultChecked onChange={changeEpisodeType} /> Just the latest episode
								</div>
								<div>
									<input type="radio" name="episodeType" value="specific" onChange={changeEpisodeType} /> A specific episode
								</div>
							</div>
							</>
						}
						{ episodeType === 'specific' &&
							<>
								<div className="configurationHeadline">Let's find your episode!</div>
								<div className="configurationItem">
									<div className="episodeList">
										{ episodes.count > 0 &&
											episodes.items.map((episode,index) => {
												return (
													<div className={'episode' + (activeEpisodeId === episode.id ? ' active' : '')} key={episode.id} onClick={() => { setActiveEpisodeId(episode.id); setActiveEpisodeName(episode.title); }}>
														{episode.title}
													</div>
												);
											})
										}
									</div>
									{ activeEpisodeName &&
										<div className="selectedEpisodeName">
											Selected: {activeEpisodeName}
										</div>
									}
								</div>
							</>
						}

						{ widgetType === 'list' && 
							<div className="configurationItem">
								<div>
									Show <input type="number" name="quantity" onChange={changeEpisodeCount} value={episodeCount} style={{width: '60px' }} /> episodes
								</div>
							</div>
						}
					</div>

					<h2 className="subHeadline">
						Configure the widget <span>to fit your website</span>
					</h2>

					<div className="configurationPanel">
						
						<div className="configurationHeadline">How large do you want the widget?</div>
						<div className="configurationItem">
							Width (pixels) <input type="number" onChange={changeWidth} value={widgetWidth} style={{width: '50px' }} />


							<div className="sliderContainer">
								<Slider
									min={100}
									max={1200}
									onChange={changeWidth}
									value={widgetWidth}
									trackStyle={{
										backgroundColor: '#0161cf',
										height: 10
									}}
									handleStyle={{
										borderColor: '#123d7e',
										height: 28,
										width: 28,
										marginLeft: -14,
										marginTop: -9,
										backgroundColor: '#164c9f',
									}}
									railStyle={{
										backgroundColor: '#00a9ff',
										height: 10
									}}
								/>
							</div>
						</div>

						<div className="configurationItem">
							Height (pixels) <input type="number" onChange={changeHeight} value={widgetHeight} style={{width: '50px' }} />
							
							<div className="sliderContainer">
								<Slider
									min={100}
									max={1200}
									onChange={changeHeight}
									value={widgetHeight}
									trackStyle={{
										backgroundColor: '#0161cf',
										height: 10
									}}
									handleStyle={{
										borderColor: '#123d7e',
										height: 28,
										width: 28,
										marginLeft: -14,
										marginTop: -9,
										backgroundColor: '#164c9f',
									}}
									railStyle={{
										backgroundColor: '#00a9ff',
										height: 10
									}}
								/>
							</div>
						</div>

						<div className="configurationHeadline">Now, let's decide on the color of your widget!</div>
						<div className="configurationItem">
							<ColorInput color={primaryColor} setColorCallback={setPrimaryColor} colorLabel={'Primary color'} />
							<ColorInput color={secondaryColor} setColorCallback={setSecondaryColor} colorLabel={'Secondary color'} />
							<ColorInput color={textColor} setColorCallback={setTextColor} colorLabel={'Text color'} />
							<ColorInput color={backgroundColor} setColorCallback={setBackgroundColor} colorLabel={'Background color'} />
						</div>
					</div>
				</div>
				<div className="preview">
					<h2 className="subHeadline">
						Preview your widget <span>to see what your visitors will see</span>
					</h2>

					<div className="widgetPreview">
						<IframeGenerator
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							textColor={textColor}
							backgroundColor={backgroundColor}
							podcastId={activePodcastId}
							activeEpisodeId={activeEpisodeId}
							showPodcastInfo={showPodcastInfo}
							episodeCount={episodeCount}
							episodeType={episodeType}
							widgetWidth={widgetWidth}
							widgetHeight={widgetHeight}
							widgetType={widgetType}
							generateAsPreview={true} 
						/>
					</div>
				</div>
			</div>

			<h2 className="subHeadline">
				Copy the code <span>and paste to your website</span>
			</h2>
			<div className="codeArea">
				<IframeGenerator
					primaryColor={primaryColor}
					secondaryColor={secondaryColor}
					textColor={textColor}
					backgroundColor={backgroundColor}
					podcastId={activePodcastId}
					activeEpisodeId={activeEpisodeId}
					showPodcastInfo={showPodcastInfo}
					episodeCount={episodeCount}
					episodeType={episodeType}
					widgetWidth={widgetWidth}
					widgetHeight={widgetHeight}
					widgetType={widgetType}
					generateAsCode={true}
				/>
			</div>
			<h2 className="subHeadline">
				That's it <span>enjoy your widget!</span>
			</h2>
			<p>
				E-mail <a href="mailto:info@podfriend.com">info@podfriend.com</a> if you have any suggestions, comments or bugs!
			</p>
			<p>
				<a href="https://www.podfriend.com">Go to www.podfriend.com</a>
			</p>
		</div>
	);
}
export default WidgetGeneratorPane;
import React from 'react';

const IframeGenerator = (props) => {
	const widgetHost = window.location.hostname === 'localhost' ? 'http://localhost:9999' : 'https://widget.podfriend.com';
	const widgetBaseUrl = '/#/podcast/';
	var iframeSource = widgetHost
					 + widgetBaseUrl
					 + props.podcastId
					 + '?showPodcastInfo=' + props.showPodcastInfo
					 + (props.widgetType === 'episode' ? '' : '&episodeCount=' + props.episodeCount)
					 + '&widgetType=' + props.widgetType
					 + (props.widgetType === 'episode' ? props.episodeType === 'latest' ? '' : '&activeEpisodeId=' + props.activeEpisodeId : '')
					 + '&primaryColor=' + props.primaryColor.replace('#','')
					 + '&secondaryColor=' + props.secondaryColor.replace('#','')
					 + '&textColor=' + props.textColor.replace('#','')
					 + '&backgroundColor=' + props.backgroundColor.replace('#','');

	var loadScriptSrc =  widgetHost
						+ '/load.js'
						+ '?podcastId=' + props.podcastId
						+ '&showPodcastInfo=' + props.showPodcastInfo
						+ '&width=' + props.widgetWidth
						+ '&height=' + props.widgetHeight
						+ (props.widgetType === 'episode' ? '' : '&episodeCount=' + props.episodeCount)
						+ '&widgetType=' + props.widgetType
						+ (props.widgetType === 'episode' ? props.episodeType === 'latest' ? '' : '&activeEpisodeId=' + props.activeEpisodeId : '')
						+ '&primaryColor=' + props.primaryColor.replace('#','')
						+ '&secondaryColor=' + props.secondaryColor.replace('#','')
						+ '&textColor=' + props.textColor.replace('#','')
						+ '&backgroundColor=' + props.backgroundColor.replace('#','');

	if (props.generateAsCode) {
		return (
			<>
				&lt;!-- Start Podfriend Podcast Widget --&gt;<br />
				&lt;script src="{loadScriptSrc}" defer&gt;&lt;/script&gt;<br />
				&lt;!-- End Podfriend Podcast Widget --&gt;
			</>
		);
		// &lt;iframe src="{iframeSource}" width="{props.widgetWidth}" height="{props.widgetHeight}" title="Podcast widget" /&gt;&lt;/iframe&gt;
	}
	else {
		return (
			<iframe
				key={iframeSource}
				width={props.widgetWidth}
				height={props.widgetHeight}
				src={iframeSource} title="Podcast widget" />
		);
	}
}
export default IframeGenerator;
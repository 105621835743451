import React from 'react';

import {
	HashRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";

// import PodcastSearchPane from './SearchPane';

import WidgetGeneratorPane from './WidgetGeneratorPane';

import './App.css';

function App(props) {
	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<WidgetGeneratorPane />
				</Route>
				<Redirect to='/' />
			</Switch>
		</Router>
	);
}

export default App;

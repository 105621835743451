import React from 'react';

import { GithubPicker } from 'react-color';

const ColorInput = ({color, setColorCallback, colorLabel}) => {
	const colors = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB','#FFFFFF', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB','#000000'];

	return (
		<div className="colorInput">
			<div>
				{colorLabel}
			</div>
			<div className="colorInputLine">
				<div className="colorPreview">
					<div className="colorPreviewInner" style={{ backgroundColor: color }}>

					</div>
				</div>
				<input type="text" value={color} onChange={(event) => { setColorCallback(event.target.value); }} />
			</div>
			<GithubPicker width={237} onChangeComplete={(color) => { setColorCallback(color.hex); }} colors={colors} />
		</div>
	);
};

export default ColorInput;